// Variables

// Panel
$panel-color: #212529 !default;
$panel-bg: #F5F5F5 !default;

// Theme
$theme-color-white: #000000;
$theme-color-black: #ffffff;
$theme-color-blue: #063462;
$theme-color-brown: #4D4B43;
$theme-color-green: #A9E44D;
$theme-bg-white: #ffffff !default;
$theme-bg-black: #000000 !default;
$theme-bg-blue: #9DD1FF !default;
$theme-bg-brown: #F7F3D6 !default;
$theme-bg-green: #3B2716 !default;

// Buttons
$link-color: $panel-color !default;
$link-bg: #f7f7f7 !default;
$link-hover: #b6b6b6 !default;
$link-border-color: #ADADAD !default;
$link-active-color: $link-color !default;
$link-active-bg: #b6b6b6 !default;
